import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import './css/basic-home-style.css';

const BasicHomeContent = () => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const texts = [
        "''Since I started using ProspectNav, I've led my team in the number of meetings set because it helps me spend more time prospecting''",
        "''The content created by ProspectNav is 80-95% ready to go and helps me engage with my unengaged accounts''",
        "''I was on a PIP before I started using ProspectNav and this quarter, I got promoted to be an Account Executive''",
        "''ProspectNav significantly cuts down the time it takes for me to do research on accounts and create personalized messaging''"
    ];

    const images = [
        "/demo1.png",
        "/demo2.png",
        "/demo3.png",
        "/demo4.png",
        "/demo5.png"
    ];

    useEffect(() => {
        const textInterval = setInterval(() => {
            setCurrentTextIndex(prevIndex => (prevIndex + 1) % texts.length);
        }, 7000); // Change text every 7 seconds

        return () => {
            clearInterval(textInterval);
        }; // Cleanup on unmount
    }, [texts.length]);

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <Container className='basic-home-container' style={{ marginTop: '-10%' }}>
            <Row>
                <div className='h1-header-div'>
                    <img src="/microscopelogo.png" alt="Microscope Logo" style={{ width: '20%', height: '175px', marginRight: '10px' }} />
                    <p>ProspectNav</p>
                </div>
            </Row>
            <Row>
                <div className='additional-info-div'>
                    <p style={{ width: '100%', marginLeft: '0%', fontSize: '24px' }}>
                        The #1 Messaging Personalizer for BDRs & Sales Professionals <br />
                        ProspectNav provides sellers with research on accounts and personalized emails, call scripts, qualification questions & more to create opportunities
                    </p>
                </div>
            </Row>
            <Row>
                <div className='flow-div'>
                    <Row className='row' style={{ marginTop: '-40px' }}>
                        <Col md={3}>
                            <img src="/lightbulb1.png" alt="Lightbulb Icon" style={{ width: '30%', height: '30%', marginBottom: '10px' }} />
                            <p>Set 3x the meetings & spend more time reaching out to target customers</p>
                        </Col>
                        <Col md={1}>
                            <div className='right-arrow-div'>
                                &rarr;
                            </div>
                        </Col>
                        <Col md={3}>
                            <img src="/searchmagnifying2.png" alt="Magnifying Glass Icon" style={{ width: '30%', height: '30%', marginBottom: '10px' }} />
                            <p>Save 90% of the time spent researching accounts & personalizing messaging</p>
                        </Col>
                        <Col md={1}>
                            <div className='right-arrow-div'>
                                <b>&rarr;</b>
                            </div>
                        </Col>
                        <Col md={3}>
                            <img src="/chatbubble3.png" alt="Chat Bubble Icon" style={{ width: '30%', height: '30%', marginBottom: '10px' }} />
                            <p>Start sourcing opportunities for free, hit your number & get promoted by signing up</p>
                        </Col>
                    </Row>
                </div>
            </Row>
            <Row style={{ height: '50px' }}>
                {/* Empty section for spacing */}
            </Row>
            <Row>
                <div className='banner-div'>
                    <p>{texts[currentTextIndex]}</p>
                </div>
            </Row>
            <Row style={{ height: '50px' }}>
                {/* Empty section for spacing */}
            </Row>
            <Row className="justify-content-center">
            </Row>
            <Row className="justify-content-center">
                <Col md={1} className="text-center" style={{marginTop: '30%'}}>
                    <Button onClick={handlePrevImage} style={{ backgroundColor: '#00c3b1', borderColor: '#00c3b1' }}>&larr;</Button>
                </Col>
                <Col md={8} className="text-center"> 
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <img
                            className="d-block"
                            src={images[currentImageIndex]}
                            alt={`Demo image ${currentImageIndex + 1}`}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </div>
                </Col>
                <Col md={1} className="text-center" style={{marginTop: '30%'}}>
                    <Button onClick={handleNextImage} style={{ backgroundColor: '#00c3b1', borderColor: '#00c3b1' }}>&rarr;</Button>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={8} className="text-center">
                    <div className='info-div' style={{ marginBottom: '20px', width: '75%', marginLeft: '15%', fontSize: '28px'}}>
                        <p>How ProspectNav Sources Opportunities</p>
                    </div>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col md={6}>
                    <div className='text-box-div'>
                        <p>ProspectNav searches the web and recent investment documents for your target company’s top business objectives, mission, and upcoming initiatives. ProspectNav will provide you with this information and more such as company overviews, competitors, challenges, opportunities, and industry analysis to help you have ideal customer-centric conversations with prospects.</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <img src="/searchesweb.png" alt="Searches Web" style={{ width: '35%', height: '35%' }} />
                    </div>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col md={6}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <img src="/formimages.png" alt="Form Images" style={{ width: '70%', height: '70%', marginTop: '30px' }} />
                    </div>
                </Col>
                <Col md={6}>
                    <div className='text-box-div'>
                        <p>Upon signing-up, ProspectNav uses its intelligence to identify your company's unique differentiators, value propositions, and customer success stories (which you can edit) to optimize messaging.</p>
                    </div>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col md={6}>
                    <div className='text-box-div'>
                        <p>Working backwards from your target company’s information and yours, ProspectNav uses state of the art generative AI to create messaging that leads to you creating opportunities and getting promoted in a faster period of time.</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <img src="/peopleconnect.png" alt="People Connect" style={{ width: '35%', height: '35%' }} />
                    </div>
                </Col>
            </Row>
            <Row style={{ height: '50px' }}>
                {/* Empty section for spacing */}
            </Row>
            <Row className="justify-content-center" style={{ marginBottom: '50px' }}>
                <Col md={12} className="text-center">
                    <img
                        src="/prospectnavplans8.png"
                        alt="ProspectNav Plans"
                        style={{ width: '90%', height: 'auto' }}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default BasicHomeContent;
